<template>
  <div class="pt-share">
    <h2 v-if="contentText.title" class="mt-3 mb-6">{{ contentText.title }}</h2>

    <p v-if="contentText.description" class="mb-12">
      {{ contentText.description }}
    </p>

    <social-sharing
      v-if="shareNetworks && shareNetworks.length > 0"
      class="justify-center mb-9"
      :share-networks="shareNetworks"
      :url="url"
      :title="title"
      :description="description"
    />

    <div class="pt-share__link-box">
      <span id="linkToCopy" class="link-copy">{{ url }}</span>
      <button class="pt-share__icon-box" type="button" @click="copyToClipBoard($event)">
        <icon name="pt-copy" />
        <span class="pt-share__tooltip text-xs" :class="showTooltip ? 'copied' : ''">
          {{ $t('formation.courses.inscription.copyLink') }}
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import SocialSharing from '~/components/SocialSharing';
export default {
  name: 'ShareModal',
  components: {
    SocialSharing
  },
  props: {
    shareNetworks: {
      type: Array,
      default: () => []
    },
    url: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    contentText: {
      type: Object,
      default: () => ({ title: '', description: '' })
    }
  },
  data() {
    return {
      showTooltip: false,
      urlToShare: process.env.APP_BASE_DOMAIN + this.$route.fullPath
    };
  },
  methods: {
    copyToClipBoard() {
      const linkCopy = document.getElementById('linkToCopy').innerHTML;
      navigator.clipboard.writeText(linkCopy);
      this.showTooltip = !this.showTooltip;
    }
  }
};
</script>
<style src="./style.scss" lang="scss" />
