<template>
  <div>
    <button
      class="justify-center btn bg-white-dark text-gray h-10 lg:w-full"
      type="button"
      @click.stop.prevent="modalAction('share')"
    >
      <span> {{ $t('ui.button.share') }} </span>
      <icon name="pt-share-alt" class="text-xl ml-1" :aria-label="$t('ui.button.share')" />
    </button>
    <client-only>
      <modal-wrapper name="share">
        <ShareModal
          :share-networks="shareNetworks"
          :url="url"
          :title="title"
          :description="description"
          :content-text="contentText"
        />
      </modal-wrapper>
    </client-only>
  </div>
</template>

<script>
import ModalWrapper from '@/components/ModalWrapper';
import ShareModal from '@/components/Summary/ShareModal';
export default {
  name: 'Share',
  components: {
    ModalWrapper,
    ShareModal
  },
  props: {
    shareNetworks: {
      type: Array,
      default: () => []
    },
    url: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    contentText: {
      type: Object,
      default: () => ({ title: '', description: '' })
    }
  },
  methods: {
    modalAction(modalName) {
      this.$modal.open(modalName);
      this.actualModal = modalName;
    }
  }
};
</script>
