<template>
  <ul class="pt-social-sharing">
    <li v-for="{ network, username, icon } in shareNetworks" :key="network" :aria-label="network">
      <share-network
        :key="network"
        :network="network"
        :tag="tag"
        :url="url"
        :title="username ? `${title} ${username}` : title"
        :description="description"
        :quote="quote"
        :hashtags="hashtags"
        :twitter-user="twitterUser"
        :media="media"
        :popup="popup"
        @open="open"
        @change="change"
        @close="close"
      >
        <icon :name="icon" :title="capitalize(network)" />
      </share-network>
    </li>
  </ul>
</template>

<script>
import { capitalize } from '~/utils/helpers/string';

export default {
  name: 'PtSocialSharing',

  /*
   * @see https://github.com/nicolasbeauvais/vue-social-sharing#available-networks-and-properties
   */
  props: {
    shareNetworks: {
      type: Array,
      default: () => []
    },
    tag: {
      type: String,
      default: 'a'
    },
    popup: {
      type: Object,
      default: () => ({
        width: 626,
        height: 426
      })
    },
    url: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    quote: {
      type: String,
      default: ''
    },
    hashtags: {
      type: String,
      default: ''
    },
    twitterUser: {
      type: String,
      default: ''
    },
    media: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    capitalize
  }),

  methods: {
    /**
     * Event `@open` fired when a sharing popup is open
     * Data: Network name, shared url
     * @event open
     * @type {object}
     */
    open(data) {
      this.$emit('open', data);
    },
    /**
     * Event `@open` fired when the createUser open a new sharing popup while another is already open
     * Data: Network name, shared url
     * @event open
     * @type {object}
     */
    change(data) {
      this.$emit('change', data);
    },
    /**
     * Event `@open` fired when a sharing popup is closed or changed by another popup
     * Data: Network name, shared url
     * @event open
     * @type {object}
     */
    close(data) {
      this.$emit('close', data);
    }
  }
};
</script>

<style lang="scss" src="./style.scss" scoped />
